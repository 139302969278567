// extracted by mini-css-extract-plugin
export var MuiSliderMark = "stacked-container-module--MuiSlider-mark--c05cb";
export var MuiSliderMarkLabel = "stacked-container-module--MuiSlider-markLabel--a353e";
export var MuiSliderRail = "stacked-container-module--MuiSlider-rail--60a8a";
export var MuiSliderRoot = "stacked-container-module--MuiSlider-root--5d6d9";
export var MuiSliderThumb = "stacked-container-module--MuiSlider-thumb--25abe";
export var active = "stacked-container-module--active--4dacc";
export var amountSlider = "stacked-container-module--amount-slider--9d7f5";
export var bar = "stacked-container-module--bar--735d7";
export var blank = "stacked-container-module--blank--e567b";
export var btnShowWine = "stacked-container-module--btn-show-wine--1d99e";
export var btnWrap = "stacked-container-module--btn-wrap--e4d38";
export var contentBody = "stacked-container-module--content-body--86d81";
export var contentHeading = "stacked-container-module--content-heading--5f086";
export var distributeEnd = "stacked-container-module--distributeEnd--72096";
export var distributeEvenly = "stacked-container-module--distributeEvenly--e8353";
export var distributeFill = "stacked-container-module--distributeFill--4207a";
export var distributeMiddle = "stacked-container-module--distributeMiddle--dc991";
export var distributeStart = "stacked-container-module--distributeStart--8abf2";
export var dot = "stacked-container-module--dot--cd330";
export var emailInput = "stacked-container-module--email-input--1f5cc";
export var emailPage = "stacked-container-module--email-page--476ad";
export var horizontal = "stacked-container-module--horizontal--71287";
export var introBody = "stacked-container-module--intro-body--dbc2f";
export var introBtn = "stacked-container-module--intro-btn--aa6f5";
export var introTexts = "stacked-container-module--intro-texts--a0ade";
export var label = "stacked-container-module--label--6c210";
export var last = "stacked-container-module--last--65357";
export var mark = "stacked-container-module--mark--ecd53";
export var offersList = "stacked-container-module--offers-list--ab96e";
export var optList = "stacked-container-module--opt-list--d0bc4";
export var pageContent = "stacked-container-module--page-content--e3624";
export var pageNavigator = "stacked-container-module--page-navigator--6682d";
export var questionItem = "stacked-container-module--question-item--3da24";
export var questionText = "stacked-container-module--question-text--d70cd";
export var questionaire = "stacked-container-module--questionaire--222a9";
export var rankSelect = "stacked-container-module--rank-select--f9cad";
export var ranking = "stacked-container-module--ranking--321e6";
export var resultsPage = "stacked-container-module--results-page--2f811";
export var reversed = "stacked-container-module--reversed--a4e16";
export var slider = "stacked-container-module--slider--1ffc5";
export var spacer = "stacked-container-module--spacer--9413b";
export var spending = "stacked-container-module--spending--cdece";
export var stackItem = "stacked-container-module--stackItem--839c4";
export var stackSpaced1 = "stacked-container-module--stackSpaced1--b8cdc";
export var stackSpaced2 = "stacked-container-module--stackSpaced2--c6777";
export var stackSpaced3 = "stacked-container-module--stackSpaced3--2a0d5";
export var stackSpaced4 = "stacked-container-module--stackSpaced4--bfaae";
export var stackedContainer = "stacked-container-module--stackedContainer--8a3c2";
export var stepPages = "stacked-container-module--step-pages--cf9b6";
export var stopBar = "stacked-container-module--stop-bar--7b7f0";
export var stops = "stacked-container-module--stops--611a1";
export var subheading = "stacked-container-module--subheading--4c0c8";
export var title = "stacked-container-module--title--e375b";
export var toLogin = "stacked-container-module--to-login--377df";