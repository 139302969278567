import * as React from "react"

import { getSpendings, writeSpendings } from "../../utils/localstorage"

import Slider from "@mui/material/Slider"
import { Box } from "@vinomofo/components"
import ContentHeading from "../content-heading"
import database from "../../../data.json"


const Spending: React.FC<T> = ({ data }) => {
  const [spendings, setSpendingState] = React.useState({
    whites: null,
    reds: null,
    sparkling: null,
  })
  React.useEffect(() => {
    const currentSpendings = getSpendings()
    writeSpendings(currentSpendings ? currentSpendings : {})
    setSpendingState(currentSpendings ? currentSpendings : {})
  }, [])

  React.useEffect(() => {
    writeSpendings(spendings)
  }, [spendings])

  const setSpending = (varietal: string, amount: string) => {
    setSpendingState({ ...spendings, [varietal]: amount })
  }
  return (
    <>
      <ContentHeading title={data.title} subtitle={data.subtitle} />
      <Box className={`content-body`} marginBottom={60}>
        <div className="spending">
          <h2 className="subheading">Reds</h2>
          <div className="slider">
            <Slider
              value={
                database.spending.marks.filter(
                  (mark: { value: number; label: string }) =>
                    mark.label === spendings.reds
                )[0]?.value || 1
              }
              step={1}
              min={1}
              max={7}
              marks={database.spending.marks}
              onChange={(event, value) => {
                setSpending(
                  "reds",
                  database.spending.marks.filter(
                    (mark: { value: number; label: string }) =>
                      mark.value === value
                  )[0].label
                )
              }}
            />
          </div>
          <h2 className="subheading">Whites/Rosè</h2>
          <div className="slider">
            <Slider
              value={
                database.spending.marks.filter(
                  (mark: { value: number; label: string }) =>
                    mark.label === spendings.whites
                )[0]?.value || 1
              }
              step={1}
              min={1}
              max={7}
              marks={database.spending.marks}
              onChange={(event, value) => {
                setSpending(
                  "whites",
                  database.spending.marks.filter(
                    (mark: { value: number; label: string }) =>
                      mark.value === value
                  )[0].label
                )
              }}
            />
          </div>
          <h2 className="subheading">Sparkling</h2>
          <div className="slider">
            <Slider
              value={
                database.spending.marks.filter(
                  (mark: { value: number; label: string }) =>
                    mark.label === spendings.sparkling
                )[0]?.value || 1
              }
              step={1}
              min={1}
              max={7}
              marks={database.spending.marks}
              onChange={(event, value) => {
                setSpending(
                  "sparkling",
                  database.spending.marks.filter(
                    (mark: { value: number; label: string }) =>
                      mark.value === value
                  )[0].label
                )
              }}
            />
          </div>
        </div>
      </Box>
    </>
  )
}

export default Spending
