// extracted by mini-css-extract-plugin
export var MuiSliderMark = "sizing-wrapper-module--MuiSlider-mark--e533e";
export var MuiSliderMarkLabel = "sizing-wrapper-module--MuiSlider-markLabel--26098";
export var MuiSliderRail = "sizing-wrapper-module--MuiSlider-rail--1e36a";
export var MuiSliderRoot = "sizing-wrapper-module--MuiSlider-root--21ea0";
export var MuiSliderThumb = "sizing-wrapper-module--MuiSlider-thumb--c36e1";
export var active = "sizing-wrapper-module--active--54073";
export var amountSlider = "sizing-wrapper-module--amount-slider--f7b3b";
export var bar = "sizing-wrapper-module--bar--a775d";
export var blank = "sizing-wrapper-module--blank--dc8f3";
export var btnShowWine = "sizing-wrapper-module--btn-show-wine--cf758";
export var btnWrap = "sizing-wrapper-module--btn-wrap--3739a";
export var contentBody = "sizing-wrapper-module--content-body--ae6ec";
export var contentHeading = "sizing-wrapper-module--content-heading--feafe";
export var dot = "sizing-wrapper-module--dot--2eb8d";
export var emailInput = "sizing-wrapper-module--email-input--02478";
export var emailPage = "sizing-wrapper-module--email-page--86c42";
export var introBody = "sizing-wrapper-module--intro-body--4f5bf";
export var introBtn = "sizing-wrapper-module--intro-btn--2e1b5";
export var introTexts = "sizing-wrapper-module--intro-texts--3227d";
export var label = "sizing-wrapper-module--label--01c7a";
export var last = "sizing-wrapper-module--last--72bb8";
export var mark = "sizing-wrapper-module--mark--3621b";
export var offersList = "sizing-wrapper-module--offers-list--47a5a";
export var optList = "sizing-wrapper-module--opt-list--7176d";
export var pageContent = "sizing-wrapper-module--page-content--b1fb6";
export var pageNavigator = "sizing-wrapper-module--page-navigator--1526c";
export var questionItem = "sizing-wrapper-module--question-item--afc3b";
export var questionText = "sizing-wrapper-module--question-text--4daa3";
export var questionaire = "sizing-wrapper-module--questionaire--32729";
export var rankSelect = "sizing-wrapper-module--rank-select--5ddc1";
export var ranking = "sizing-wrapper-module--ranking--6fd18";
export var resultsPage = "sizing-wrapper-module--results-page--5966b";
export var sizingWrapper = "sizing-wrapper-module--sizingWrapper--5b00b";
export var slider = "sizing-wrapper-module--slider--9b9b8";
export var spending = "sizing-wrapper-module--spending--0dc52";
export var stepPages = "sizing-wrapper-module--step-pages--dfac5";
export var stopBar = "sizing-wrapper-module--stop-bar--ec72d";
export var stops = "sizing-wrapper-module--stops--6d342";
export var subheading = "sizing-wrapper-module--subheading--66b64";
export var title = "sizing-wrapper-module--title--bed22";
export var toLogin = "sizing-wrapper-module--to-login--16cf0";