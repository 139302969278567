import * as styles from "./back-to-top.module.scss"

import React, { useEffect, useState } from "react"

import classNames from "../../../utils/class-names"
import fingerPointingUp from "./finger-pointing-up.png"

const scrollToTop = evt => {
  evt.preventDefault()

  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  } catch (error) {
    window.scrollTo(0, 0)
  }
}

const BackToTop = ({ large }) => {
  const [animation, setAnimation] = useState(0)

  const classes = classNames([
    styles.backToTop,
    styles[`animation${animation}`],
    large && styles.large,
  ])

  useEffect(() => {
    const timeoutFn = setTimeout(() => {
      const nextAnimation = animation < 2 ? animation + 1 : 0
      setAnimation(nextAnimation)
    }, 5000)
    return () => clearTimeout(timeoutFn)
  }, [animation])

  return (
    <button onClick={scrollToTop} className={classes}>
      <img src={fingerPointingUp} alt="Finger pointing up" />
    </button>
  )
}

export default BackToTop
