// extracted by mini-css-extract-plugin
export var MuiSliderMark = "page-section-module--MuiSlider-mark--6baed";
export var MuiSliderMarkLabel = "page-section-module--MuiSlider-markLabel--366c9";
export var MuiSliderRail = "page-section-module--MuiSlider-rail--ddaa0";
export var MuiSliderRoot = "page-section-module--MuiSlider-root--bf11e";
export var MuiSliderThumb = "page-section-module--MuiSlider-thumb--2ed31";
export var active = "page-section-module--active--f6ba7";
export var amountSlider = "page-section-module--amount-slider--7c96f";
export var bar = "page-section-module--bar--67f43";
export var blank = "page-section-module--blank--370fe";
export var btnShowWine = "page-section-module--btn-show-wine--41639";
export var btnWrap = "page-section-module--btn-wrap--838ad";
export var contentBody = "page-section-module--content-body--1d50b";
export var contentHeading = "page-section-module--content-heading--c6025";
export var dot = "page-section-module--dot--be6e4";
export var emailInput = "page-section-module--email-input--c164b";
export var emailPage = "page-section-module--email-page--dafcb";
export var fullWidth = "page-section-module--fullWidth--953ab";
export var inner = "page-section-module--inner--eae25";
export var introBody = "page-section-module--intro-body--50568";
export var introBtn = "page-section-module--intro-btn--7e27f";
export var introTexts = "page-section-module--intro-texts--1f861";
export var label = "page-section-module--label--be388";
export var last = "page-section-module--last--cfc81";
export var mark = "page-section-module--mark--81552";
export var offersList = "page-section-module--offers-list--bbf16";
export var optList = "page-section-module--opt-list--9302d";
export var pageContent = "page-section-module--page-content--e202f";
export var pageNavigator = "page-section-module--page-navigator--73e0c";
export var questionItem = "page-section-module--question-item--5f2a0";
export var questionText = "page-section-module--question-text--b0184";
export var questionaire = "page-section-module--questionaire--85b8f";
export var rankSelect = "page-section-module--rank-select--5112e";
export var ranking = "page-section-module--ranking--84bbf";
export var resultsPage = "page-section-module--results-page--1ec49";
export var section = "page-section-module--section--30998";
export var slider = "page-section-module--slider--00c45";
export var spaceAfter1 = "page-section-module--spaceAfter1--e7622";
export var spaceAfter2 = "page-section-module--spaceAfter2--a4d9b";
export var spaceAfter3 = "page-section-module--spaceAfter3--2e326";
export var spaceAfter4 = "page-section-module--spaceAfter4--6217b";
export var spaceBefore1 = "page-section-module--spaceBefore1--643fe";
export var spaceBefore2 = "page-section-module--spaceBefore2--01df5";
export var spaceBefore3 = "page-section-module--spaceBefore3--5379d";
export var spaceBefore4 = "page-section-module--spaceBefore4--0a19e";
export var spending = "page-section-module--spending--c22a5";
export var stepPages = "page-section-module--step-pages--ae706";
export var stopBar = "page-section-module--stop-bar--2ea7a";
export var stops = "page-section-module--stops--6d467";
export var subheading = "page-section-module--subheading--8e1fb";
export var title = "page-section-module--title--fd162";
export var toLogin = "page-section-module--to-login--83b57";