import * as React from "react"

import { GrNext, GrPrevious } from "react-icons/gr"

import { Box } from "@vinomofo/components"
import { PreferencesButton } from "../../components/button"

const PageNavigator: React.FC<T> = ({ changePage, currentPage }) => {
  return (
    <Box className={`page-navigator`} p={60}>
      <PreferencesButton
        size="md"
        // navIcon
        textBtn
        variant="default"
        onClick={() => {
          changePage("prev")
        }}
        disabled={currentPage <= 0}
      >
        {/* <GrPrevious size={25} /> */}
        Back
      </PreferencesButton>
      <PreferencesButton
        size="md"
        // navIcon
        textBtn
        variant="default"
        onClick={() => {
          changePage("next")
        }}
        disabled={currentPage >= 7} 
      >
        {/* <GrNext size={25} /> */}
        Next
      </PreferencesButton>
    </Box>
  )
}

export default PageNavigator
