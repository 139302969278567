import {
  Button,
  ButtonProps,
  buttonVariants
} from "@vinomofo/components";
import styled, { css } from "styled-components";

import React from "react";
import { ResponsiveValue } from "@xstyled/system";

const sizeValues = ["sm", "md", "lg"] as const;
type Size = typeof sizeValues[number];

const CheckImage =
  "data:image/svg+xml,%3Csvg width='14' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-5 -7)' fill='none' fill-rule='evenodd'%3E%3Crect fill='none' width='24' height='24' rx='4'/%3E%3Cpath d='M17 7.34l-7.33 7.34-2.68-2.67a1.17 1.17 0 0 0-1.65 1.65l3.5 3.5c.46.45 1.2.45 1.65 0l8.17-8.17A1.17 1.17 0 0 0 17 7.34z' fill='%23FFFFFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E";

export interface PreferencesButtonProps extends ButtonProps {
  block?: boolean;
  flat?: boolean;
  solid?: boolean;
  yesno?: string;
  navIcon?: boolean;
  hoverBg?: string;
  hoverBorder?: string;
  selected?: boolean;
  size?: ResponsiveValue<Size>;
  textBtn?: boolean;
}

const buttonFontSizes = {
  sm: 1,
  md: 2,
  lg: 3,
};

const getButtonFontSize = (size: ResponsiveValue<Size>) => {
  if (Array.isArray(size)) {
    return size.map((s) => buttonFontSizes[s]);
  }

  return buttonFontSizes[size as string];
};

const buttonVariants_ = {
  ...buttonVariants,
  extra: css<{block?: boolean, flat?: boolean, selected?: boolean, navIcon?: boolean, yesno?: string, hoverBg?: string, hoverColor?: string, hoverBorder?: string, backgroundColor?: string, solid?: boolean, textBtn?: boolean}>`
    cursor: pointer;
    position: relative;
    min-width: 64px;
    min-height: 52px;
      
    &:disabled {
      cursor: not-allowed;
      color:  ${({ theme }) => theme.colors.disabled};
      > svg {
        fill: ${({ theme }) => theme.colors.disabled};
  
        polyline, {
          stroke: ${({ theme }) => theme.colors.disabled};
        }
      }
    }

    ${({ block }) =>
      block && `{
        width: 100%;
        display: block;
      }`
    }

    ${({ solid }) =>
      solid && `{
        display: flex;
        justify-content: center;
        align-items: center;
      }`
    }

    ${({ theme, flat, hoverBg, hoverColor, hoverBorder, solid, backgroundColor, yesno}) =>
      flat && `{
        border-radius: 0;
        border-color:  ${solid && 'transparent' || hoverBorder} ;
        border-width: 1px;
        background-color: ${solid && backgroundColor || 'transparent'};
        outline: 0;
        color: ${theme.colors.white};

        > svg {
          fill: ${theme.colors.white};
    
          polyline,
          path {
            stroke: ${theme.colors.white};
          }
        }
      
        &:hover {
          background-color: ${hoverBg || theme.colors.brand};
          color: ${hoverColor || theme.colors.brand};
        
          &::before {
            background-position: 50%;
            background-repeat: no-repeat;
            left: 10px;
            height: 20px;
            width: 20px;
            content: "";
            position: absolute;
            top: calc(50% - 10px);
            bottom: calc(50% - 10px);
            animation: moveUp 0.5s;
          }
        }
      }`
    }
    
    ${({ theme, yesno, hoverBorder, hoverBg }) => 
      yesno !== undefined && `{
        display: flex;
        align-items: center;
        justify-content: center;
        
        &::before {
          display: none;
        }
        
        &:hover {
          background-color: ${yesno === 'no' ? "#ff0000":""};
          border-color: ${yesno === 'no' ? "#ff0000":""};
        }
        
        > svg {
          fill: ${theme.colors.white};
          font-size: 1.2em;
    
          polyline,
          path {
            stroke: ${theme.colors.white};
          }
        }
      }`
    }

    ${({ theme, selected, hoverBg, hoverColor, hoverBorder }) =>
      selected && `{
        background-color: ${hoverBg || theme.colors.brand};
        color: ${hoverColor || theme.colors.brand};
        border-color: ${hoverBorder || theme.colors.brand};
      
        &::before {
          background-image: url("${CheckImage}");
          background-position: 50%;
          background-repeat: no-repeat;
          left: 10px;
          height: 20px;
          width: 20px;
          content: "";
          position: absolute;
          top: calc(50% - 10px);
          bottom: calc(50% - 10px);
          animation: moveUp 0.5s;
        }
      }`
    }

    ${({ theme, navIcon }) =>
      navIcon && `{
        cursor: pointer;
        padding: 0.4em;
        font-size: 1.4em;
        border: 2px solid;
        border-radius: 100%;
        line-height: 1;
        min-width: 34px;
        min-height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.colors.white};

        > svg {
          fill: ${theme.colors.white};
    
          polyline,
          path {
            stroke: ${theme.colors.white};
          }
        }
      }`
    }

    ${({ theme, textBtn }) =>
      textBtn && `{
        cursor: pointer;
        padding: 0.3em 0.7em;
        font-size: 1.3em;
        line-height: 1;
        min-width: 34px;
        min-height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 5px;
        border: 1px solid rgb(211, 211, 211);
        background-color: rgb(211, 211, 211, 0.7);
        &:hover {
          background-color: rgb(211, 211, 211, 0.9);
          border-color: rgb(211, 211, 211, 0.9);
        }
      }`
    }
  `
};

export const PreferencesButton = ({
  size = "md",
  onClick = () => console.warn("No onClick event defined"),
  ...rest
}: PreferencesButtonProps): JSX.Element => {
  const buttonFontSize = getButtonFontSize(size);

  return <Button
    fontSize={buttonFontSize}
    onClick={onClick}
    {...buttonVariants_}
    {...rest}
  />
};
