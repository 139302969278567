import * as styles from "./page-section.module.scss"

import PropTypes from "prop-types"
import React from "react"
import classNames from "../../utils/class-names"

const PageSection = ({ children, ...props }) => {
  const { fullWidth, spaceBefore, spaceAfter } = props
  const { backgroundColor } = props

  const containerClasses = classNames([
    styles.section,
    spaceBefore > 0 && styles[`spaceBefore${spaceBefore}`],
    spaceAfter > 0 && styles[`spaceAfter${spaceAfter}`],
  ])

  const innerClasses = classNames([styles.inner, fullWidth && styles.fullWidth])

  return (
    <section className={containerClasses} style={{ backgroundColor }}>
      <div className={innerClasses}>{children}</div>
    </section>
  )
}

PageSection.defaultProps = {
  fullWidth: false,
  spaceBefore: 0,
  spaceAfter: 0,
  backgroundColor: "inherit",
}

PageSection.propTypes = {
  fullWidth: PropTypes.bool,
  spaceBefore: PropTypes.oneOf([0, 1, 2, 3, 4]),
  spaceAfter: PropTypes.oneOf([0, 1, 2, 3, 4]),
  backgroundColor: PropTypes.string,
}

export default PageSection
