// extracted by mini-css-extract-plugin
export var MuiSliderMark = "legals-module--MuiSlider-mark--0a9c4";
export var MuiSliderMarkLabel = "legals-module--MuiSlider-markLabel--112d2";
export var MuiSliderRail = "legals-module--MuiSlider-rail--6f6ae";
export var MuiSliderRoot = "legals-module--MuiSlider-root--5fab3";
export var MuiSliderThumb = "legals-module--MuiSlider-thumb--f7ccf";
export var active = "legals-module--active--19b26";
export var amountSlider = "legals-module--amount-slider--c07a5";
export var bar = "legals-module--bar--cf58c";
export var blank = "legals-module--blank--28a1d";
export var btnShowWine = "legals-module--btn-show-wine--3cffb";
export var btnWrap = "legals-module--btn-wrap--1a41c";
export var contentBlock = "legals-module--contentBlock--fd809";
export var contentBody = "legals-module--content-body--04e74";
export var contentHeading = "legals-module--content-heading--d3438";
export var copyright = "legals-module--copyright--94ba6";
export var dot = "legals-module--dot--66fab";
export var emailInput = "legals-module--email-input--453ed";
export var emailPage = "legals-module--email-page--fa2fb";
export var introBody = "legals-module--intro-body--ee6f5";
export var introBtn = "legals-module--intro-btn--b2a96";
export var introTexts = "legals-module--intro-texts--c6040";
export var label = "legals-module--label--c965f";
export var large = "legals-module--large--abb14";
export var last = "legals-module--last--675c2";
export var mark = "legals-module--mark--bf3ed";
export var medium = "legals-module--medium--bf7f4";
export var offersList = "legals-module--offers-list--be38b";
export var optList = "legals-module--opt-list--1253c";
export var pageContent = "legals-module--page-content--66eb6";
export var pageNavigator = "legals-module--page-navigator--a1cd0";
export var questionItem = "legals-module--question-item--804d1";
export var questionText = "legals-module--question-text--80447";
export var questionaire = "legals-module--questionaire--9055d";
export var rankSelect = "legals-module--rank-select--21b96";
export var ranking = "legals-module--ranking--3116d";
export var resultsPage = "legals-module--results-page--31ae9";
export var slider = "legals-module--slider--aac73";
export var spending = "legals-module--spending--8fa2d";
export var stepPages = "legals-module--step-pages--1c19b";
export var stopBar = "legals-module--stop-bar--de9d5";
export var stops = "legals-module--stops--f0188";
export var subheading = "legals-module--subheading--1d0d0";
export var title = "legals-module--title--f0d76";
export var toLogin = "legals-module--to-login--0d07f";