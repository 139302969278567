import * as styles from "./legals.module.scss"

import { Breakpoint, useWidthBreakpoints } from "../../../hooks/use-breakpoints"

import FooterText from "./footer-text"
import Link from "../../link"
import PageSection from "../../page-section"
import PaymentOptions from "./payment-options"
import React from "react"
import StackedContainer from "../../stacked-container"
import VinomofoLogo from "../../vinomofo-logo"
import { useMarketContext } from "../../../contexts/market-context"

const Logo = () => {
  return (
    <Link link="/">
      <VinomofoLogo />
    </Link>
  )
}

const breakpoints = [Breakpoint("large", [900, null])]

const Legals = () => {
  const market = useMarketContext()
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)
  const isHorizontal = "large" === breakpoint

  return (
    <PageSection backgroundColor="black" spaceBefore={4}>
      <div ref={breakpointRef} className={styles[breakpoint]}>
        <StackedContainer space={4}>
          <StackedContainer space={2}>
            <StackedContainer
              horizontal={isHorizontal}
              distribute={isHorizontal ? "fill" : "start"}
              space={1}
            >
              <div className={styles.contentBlock}>
                <div className={styles.title}>Hey Kids!</div>

                <FooterText />
              </div>

              <div className={styles.contentBlock}>
                <div className={styles.title}>Seriously</div>
                <p>
                  At Vinomofo, we love our wine, but we like to also lead long and
                  happy lives, and be good to the world and the people in it. We
                  all try to drink responsibly, in moderation, and we really hope
                  you do too.
                </p>
                <p>Don’t be that person…</p>
              </div>
            </StackedContainer>
            {market.code === "AU" &&
              <div className={styles.contentBlock}>
                <div className={styles.title}>Acknowledgement of Country</div>
                <p>Vinomofo acknowledges the Aboriginal and Torres Strait Islander people as the traditional custodians of the land on which we live and work. We pay our respects to their Elders past, present and emerging, and recognise their continued connection to the land and waters of this country.</p>
                <p>We acknowledge this place always was, and always will be Aboriginal land.</p>
              </div>
            }
          </StackedContainer>
          <div className={styles.copyright}>
            <Logo />
            <p>Nope, we don't do the copyright &copy; thing.</p>
            <PaymentOptions />
          </div>
        </StackedContainer>
      </div>
    </PageSection>
  )
}

export default Legals
