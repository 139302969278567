import * as React from "react"

import { Box, ToggleSwitch } from "@vinomofo/components"
import { GrCheckmark, GrClose } from "react-icons/gr";
import { getAnswers, writeAnswers } from "../../utils/localstorage"

import ContentHeading from "../content-heading"
import { PreferencesButton } from "../../components/button"

const Questionaire: React.FC<T> = ({ data }) => {
  const [answers, setAnswerData] = React.useState<T>({})
  const setAnswer = (answer: string, question: string, i: number) => {
    setAnswerData({ ...answers, [i]: { question, answer } })
  }

  React.useEffect(() => {
    const currentAnswers = getAnswers()
    writeAnswers(currentAnswers ? currentAnswers : {})
    setAnswerData(currentAnswers ? currentAnswers : {})
  }, [])

  React.useEffect(() => {
    writeAnswers(answers)
  }, [answers])
  return (
    <>
      <ContentHeading title={data.title} subtitle={data.subtitle} />
      <Box className={`content-body`}>
        <div className="questionaire">
          <div className="question-item">
            <div className="question-text blank"></div>
            <div className="label">Tick all that apply</div>
            {/* <div className="label">No</div> */}
          </div>
          {data.questions.map((question: string, i: number) => (
            <div className="question-item">
              <div className="question-text">{question}</div>
              <div className="btn-wrap">
                {/* <ToggleSwitch
                  name={`question_${i}`}
                  onBlur={()=>{}}
                  value={answers[i] && answers[i].answer === "yes"}
                  onChange={() => setAnswer(answers[i].answer === "no" ? "yes" : "no", question, i)}
                /> */}
                <PreferencesButton 
                  flat
                  solid
                  size="md" 
                  variant="outline" 
                  hoverColor="white"
                  hoverBg="#77b204"
                  hoverBorder="#77b204"
                  backgroundColor="rgba(0,0,0,0.25)"
                  borderColor="rgba(0,0,0,0.25)"
                  yesno={'yes'}
                  selected={answers[i] && answers[i].answer === "yes"}
                  onClick={() => setAnswer(answers[i].answer === "no" ? "yes" : "no", question, i)}
                >
                  {answers[i] && answers[i].answer === "yes" &&(<GrCheckmark />)}
                </PreferencesButton>
              </div>
              {/* <PreferencesButton 
                flat
                size="md" 
                variant="outline" 
                hoverColor="white"
                hoverBg="#ff0000"
                hoverBorder="#ff0000"
                yesno={'no'}
                selected={answers[i] && answers[i].answer === "no"}
                onClick={() => setAnswer("no", question, i)}
              >
                {answers[i] && answers[i].answer === "no" &&(<GrClose />)}
              </PreferencesButton> */}
            </div>
          ))}
        </div>
      </Box>
    </>
  )
}

export default Questionaire
