import * as React from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { getRanks, writeRanks } from "../../utils/localstorage"

import { Box } from "@vinomofo/components"
import ContentHeading from "../content-heading"
import Dropdown from "../dropdown"
import { flex } from "@xstyled/system"

const Icon = ({ color }: { color: string }) => {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 "
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.8452" cy="1.8452" r="1.8452" fill={color || "#999"} />
      <circle cx="1.8452" cy="8.50001" r="1.8452" fill={color || "#999"} />
      <circle cx="1.8452" cy="15.1548" r="1.8452" fill={color || "#999"} />
      <circle cx="9.58897" cy="1.8452" r="1.8452" fill={color || "#999"} />
      <circle cx="9.58897" cy="8.50001" r="1.8452" fill={color || "#999"} />
      <circle cx="9.58897" cy="15.1548" r="1.8452" fill={color || "#999"} />
    </svg>
  )
}

const grid = 8

const getItemStyle = (draggableStyle: any, isDragging: any) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: 5,
  color: isDragging ? "white" : "#333",
  background: isDragging ? "#48a90f" : "white",
  textAlign: "left",
  display: "flex",
  flexDirection: "row",
  gap: 20,
  justifyContent: "space-between",
  alignItems: "center",
  ...draggableStyle,
})
const getListStyle = {
  background: "transparent",
  padding: grid,
  width: "auto",
  position: "relative",
}

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const Ranking: React.FC<T> = ({ data, selections }) => {
  const [selectedOption, setSelectedOption] = React.useState<T>({})

  const selectedWines = [
    ...Object.keys(selections.reds),
    ...Object.keys(selections.whites),
    ...Object.keys(selections.sparkling),
  ]
  const sortWines = () => {
    let sortedWines = {}
    selectedWines.sort().map((item, rank) => {
      sortedWines = { ...sortedWines, [rank]: item }
    })
    return sortedWines
  }

  React.useEffect(() => {
    const currentSelectionOption = getRanks()
    const hasCurrent =
      currentSelectionOption && Object.keys(currentSelectionOption).length > 0
    writeRanks(hasCurrent ? currentSelectionOption : sortWines())
    setSelectedOption(hasCurrent ? currentSelectionOption : sortWines())
  }, [])

  const [items, setItems] = React.useState<any>([])
  React.useEffect(() => {
    writeRanks(selectedOption)
    const data = Object.values(selectedOption).map((k) => ({
      id: `item-${k}`,
      content: `${k}`,
    }))
    setItems(data)
  }, [selectedOption])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const newOrder = reorder(
      items,
      result.source.index,
      result.destination.index
    )
    setItems(newOrder)
    let reOrdered = {}
    newOrder.map((item: any, rank) => {
      reOrdered = { ...reOrdered, [rank]: item.content }
    })
    setSelectedOption(reOrdered)
  }

  return (
    <>
      <ContentHeading title={data.title} subtitle={data.subtitle} />
      <Box className={`content-body`} px={[60]}>
        <div className="ranking">
          <p>Pick Your top drops</p>
          {items ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle}
                    {...provided.droppableProps}
                  >
                    {items.length > 10 && (
                      <div
                        style={{
                          borderTop: "2px dotted #fff",
                          width: "100%",
                          height: 1,
                          margin: "10px 0",
                          position: "absolute",
                          top: 625,
                          left: 0,
                        }}
                      />
                    )}
                    {items.map((item: any, index: number) => (
                      <>
                        {index === 10 && (
                          <div
                            style={{
                              width: "100%",
                              height: 1,
                              margin: "10px 0",
                            }}
                          />
                        )}
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <div>
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  provided.draggableProps.style,
                                  snapshot.isDragging
                                )}
                              >
                                <div style={{ paddingTop: 3 }}>
                                  {item.content}
                                </div>
                                <Icon
                                  color={snapshot.isDragging ? "#fff" : "#999"}
                                />
                              </div>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </div>
      </Box>
    </>
  )
}

export default Ranking
