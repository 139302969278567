import * as styles from "./sizing-wrapper.module.scss"

import PropTypes from "prop-types"
import React from "react"

const SizingWrapper = ({ height, width, children }) => {
  return (
    <div className={styles.sizingWrapper} style={{ height, width }}>
      {React.Children.only(children)}
    </div>
  )
}

SizingWrapper.defaultProps = {
  height: "100%",
  width: "100%",
}

SizingWrapper.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default SizingWrapper
