import * as React from "react"

import {
  getPage,
  getSelections,
  writePage,
  writeRanks,
  writeSelections,
} from "../utils/localstorage"

import EmailPage from "../components/pages/email"
import PageLayout from "../components/page-layout"
import PageNavigator from "../components/PageNavigator/PageNavigator"
import PageProgress from "../components/page-progess"
import { PageProps } from "gatsby"
import PageSection from "lib-vinomofo/components/page-section"
import Questionaire from "../components/pages/questionaire"
import Ranking from "../components/pages/ranking-v2"
import ResultsPage from "../components/pages/results"
import Spending from "../components/pages/spending"
import VarietalsSelection from "../components/pages/varietal-selection"
import _database from "../../data.json"
import Intro from "../components/pages/intro"

type Selections = {
  reds: string[]
  sparkling: string[]
  whites: string[]
}
type SelectionKey = keyof Selections
type Key = string

const database: any = _database
const IndexPage: React.FC<PageProps> = () => {
  const defaultData = {
    reds: [],
    sparkling: [],
    whites: [],
  }
  const [activeData, setActiveData] = React.useState<Key>(
    database.varietals["1"]
  )
  const [page, setPage] = React.useState<Key>("0")
  const [selections, setSelectionState] = React.useState<Selections>({
    ...defaultData,
  })

  React.useEffect(() => {
    const currentSelections = getSelections()
    writeSelections(currentSelections ? currentSelections : { ...defaultData })
    setSelectionState(
      currentSelections ? currentSelections : { ...defaultData }
    )
    const currentPage = getPage() || "0"
    if (currentPage) {
      writePage(currentPage)
      setPage(currentPage)
      switch (parseInt(currentPage)) {
        case 0:
          return setActiveData(database.intro)
        case 4:
          return setActiveData(database.ranking)
        case 5:
          return setActiveData(database.spending)
        case 6:
          return setActiveData(database.questionaire)
        case 7:
          return setActiveData(database.email)
        default:
          return setActiveData(database.varietals[parseInt(currentPage)])
      }
    }
  }, [])

  React.useEffect(() => {
    writeSelections(selections)
    writePage(page)
  }, [selections, page])

  const setSelection = (varietal: SelectionKey, data: string) => {
    const isSelected = selections[varietal][data] || false
    if (!isSelected) {
      setSelectionState({
        ...selections,
        [varietal]: { ...selections[varietal], [data]: true },
      })
    } else {
      delete selections[varietal][data]
      setSelectionState({ ...selections })
    }
  }

  const changePage = (direction: string) => {
    let current = parseInt(page)
    let next = 0
    if (direction === "next") next = current + 1
    if (direction === "prev") next = current - 1

    // Reset Ranking every press of back button on ranking page
    if (next === 3 && parseInt(page) === 4) writeRanks({})

    setPage(next.toString())

    switch (next) {
      case 0:
        return setActiveData(database.intro)
      case 4:
        return setActiveData(database.ranking)
      case 5:
        return setActiveData(database.spending)
      case 6:
        return setActiveData(database.questionaire)
      case 7:
        return setActiveData(database.email)
      default:
        return setActiveData(database.varietals[next])
    }
  }

  let Display = null
  if (selections) {
    if (parseInt(page) === 0) {
      Display = <Intro data={database.intro} changePage={changePage} />
    }
    if (parseInt(page) > 0 && parseInt(page) <= 3) {
      const varietalKey: SelectionKey = database.varietals[page].varietal
      Display = (
        <VarietalsSelection
          data={database.varietals[page]}
          setSelection={setSelection}
          selected={selections[varietalKey]}
          changePage={changePage}
        />
      )
    }
    if (parseInt(page) === 4) {
      Display = (
        <Ranking
          data={database.ranking}
          selections={selections}
          changePage={changePage}
        />
      )
    }
    if (parseInt(page) === 5) {
      Display = (
        <Spending
          data={database.spending}
          selections={selections}
          changePage={changePage}
        />
      )
    }
    if (parseInt(page) === 6) {
      Display = (
        <Questionaire
          data={database.questionaire}
          selections={selections}
          changePage={changePage}
        />
      )
    }
    if (parseInt(page) === 7) {
      Display = (
        <EmailPage
          data={database.email}
          selections={selections}
          changePage={changePage}
        />
      )
    }
    if (parseInt(page) === 8) {
      Display = (
        <ResultsPage
          data={database.results}
          selections={selections}
          changePage={changePage}
        />
      )
    }
  }

  return (
    <PageLayout>
      <PageSection fullWidth>
        <div
          className={`step-pages`}
          style={{ backgroundImage: `url("${activeData.background}")` }}
        >
          <PageSection>
            <div className={`page-content`}>
              {parseInt(page) > 0 && (
                <PageProgress
                  value={Math.round((100 * page) / 7 / 10) * 10}
                  max={100}
                />
              )}
              {Display}
              <PageNavigator changePage={changePage} currentPage={page} />
            </div>
          </PageSection>
        </div>
      </PageSection>
    </PageLayout>
  )
}

export default IndexPage
