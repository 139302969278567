import * as styles from "./stacked-container.module.scss"

import React, { Fragment } from "react"

import PropTypes from "prop-types"
import classNames from "../../utils/class-names"

const distributeStyles = {
  start: styles.distributeStart,
  middle: styles.distributeMiddle,
  end: styles.distributeEnd,
  evenly: styles.distributeEvenly,
  fill: styles.distributeFill,
}

const WrapNodes = ({ space = 0, children }) => {
  const spacerClasses = classNames([
    styles.spacer,
    space > 0 && styles[`stackSpaced${space}`],
  ])

  return React.Children.map(children, (item, index) => {
    return (
      <Fragment key={index}>
        {space > 0 && <div className={spacerClasses} />}
        <div className={styles.stackItem}>{item}</div>
      </Fragment>
    )
  })
}

const StackedContainer = ({
  children,
  space,
  distribute,
  horizontal,
  reverse,
}) => {
  const classes = classNames([
    styles.stackedContainer,
    distributeStyles[distribute],
    horizontal && styles.horizontal,
    reverse && styles.reversed,
  ])
  const [firstChild, ...remainingChildren] = React.Children.toArray(children)

  return (
    <div className={classes}>
      <WrapNodes>{[firstChild]}</WrapNodes>
      <WrapNodes space={space}>{remainingChildren}</WrapNodes>
    </div>
  )
}

StackedContainer.propTypes = {
  space: PropTypes.oneOf([0, 1, 2, 3, 4]),
  distribute: PropTypes.oneOf(["start", "middle", "end", "evenly", "fill"]),
  horizontal: PropTypes.bool,
  reverse: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

StackedContainer.defaultProps = {
  space: 0,
  distribute: "start",
  horizontal: false,
  reverse: false,
}

export default StackedContainer
